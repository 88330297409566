//Tstar font replaces the Roboto-Mono font
$tstar_font_update: true;
$roboto-mono_regular: roboto-mono_regular, Helvetica, Arial, Sans-Serif;
$tstar-bold-font: 'nanumbarungothic-bold', 'tstar_mono_roundbold', Helvetica, Arial, Sans-Serif;
$tstar-font: 'nanumbarungothic-regular', 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
$opc_update: true;
$loyalty_v2: true;
//loyalty font-families
$loyalty-tstar-font: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
$loyalty-ano-bold-font: 'ano-bold_regular', Helvetica, Arial, sans-serif;

$password_strengthen: true;
$appt_booking: true;

$fonts_update: true;
$ano-bold_regular-font: 'ano-bold_regular', 'seoulnamsan-cb', Helvetica, Arial, sans-serif;
$ano-black_regular-font: 'ano-black_regular', 'seoulnamsan-cb', Helvetica, Arial, sans-serif;
$helvetica-font: Helvetica, 'nanumbarungothic-regular', Arial, sans-serif;
@if $fonts_update == false {
  $ano-bold_regular-font: 'seoulnamsan-cb', 'ano-bold_regular', Helvetica, Arial,
    Sans-Serif;
}

// Nav Redesign 2020.
$cr20: true;

// 2021 styling refresh.
$cr21: true;

//OAB Progress bar header redesign
$appt_progress_bar_header_redesign: true;

// Account Page specific overrides
$regional_shared_account_social_login_status_panel: true;
$kr_social_section_override: true;
